<template>
  <div>
    <b-row>
      <b-col>
        <header-slot no-border-bottom v-if="moduleId != 5"> </header-slot>
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: toSendTabName }"
            exact
            exact-active-class="active"
            :active="isToSendTab"
            :link-classes="['px-3', bgTabsNavs]"
            >To Send
            <span v-if="cReturnedDeptLetters > 0 && moduleId !== 28" class="ml-2">
              <feather-icon
                icon
                :badge="cReturnedDeptLetters > 99 ? '99+' : cReturnedDeptLetters"
                badge-classes="badge-danger"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: completedTabName }"
            exact
            exact-active-class="active"
            :active="isCompletedTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Completed
          </b-nav-item>
          <b-nav-item
            :to="{ name: deletedTabName }"
            exact
            exact-active-class="active"
            :active="isDeletedTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Deleted
          </b-nav-item>
          <b-nav-item
            :to="{ name: expiredTabName }"
            exact
            exact-active-class="active"
            :active="isExpiredTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Expired
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      cReturnedDeptLetters:
        "SpecialistsDigitalRoundLettersStore/G_COUNTER_DEPT_RETURNED_LETTERS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    toSendTabName() {
      const { routeToSend } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeToSend;
    },

    completedTabName() {
      const { routeCompleted } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeCompleted;
    },

    deletedTabName() {
      const { routeDeleted } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeDeleted;
    },

    expiredTabName() {
      const { routeExpired } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeExpired;
    },

    isToSendTab() {
      const { routeToSendPending, routeToSendInQueue, routeToSendReturned } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return [
        routeToSendPending,
        routeToSendInQueue,
        routeToSendReturned,
      ].includes(this.$route.name);
    },

    isCompletedTab() {
      const { routeCompletedInCorrespondence, routeCompletedSent } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return [routeCompletedInCorrespondence, routeCompletedSent].includes(
        this.$route.name
      );
    },

    isDeletedTab() {
      return this.deletedTabName == this.$route.name;
    },

    isExpiredTab() {
      return this.expiredTabName == this.$route.name;
    },
  },
  async created() {
    await this.A_COUNTER_DEPT_RETURNED_LETTERS(this.moduleId);
  },
  methods: {
    ...mapActions({
      A_COUNTER_DEPT_RETURNED_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_DEPT_RETURNED_LETTERS",
    }),
  },
};
</script>
